import Vue from "vue"
import VueRouter from "vue-router"
import {getAccessToken} from "@/helper.js";

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home"),
		meta: {auth: true}
	},
	{
		path: "/staffs",
		name: "Staffs",
		component: () => import("@/views/Staffs"),
		meta: {auth: true}
	},
	{
		path: "/attendance",
		name: "Attendance",
		component: () => import("@/views/Attendance"),
		meta: {auth: true}
	},
	{
		path: "/leave-requests",
		name: "Leave Requests",
		component: () => import("@/views/Leaves"),
		meta: {auth: true}
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login"),
		meta: {auth: true}
	},
	{
		path: "/report",
		name: "Report",
		component: () => import("@/views/Report"),
		meta: {auth: true}
	},
	{
		path: "/:catchAll(.*)",
		component: () => import("@/views/NotFound"),
		name: "NotFound"
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.name !== "Login" && !getAccessToken()) next({name: "Login"})
	next()
})

export default router
