import Vue from "vue"
import App from "@/App.vue"
import store from "@/store"
import router from "@/router"
import vuetify from "@/plugins/vuetify"
import moment from "moment"

import "leaflet/dist/leaflet.css"
import {Icon} from "leaflet"

import AXIOS from "@/axios"
import helper from "@/helper"
import { formatUnixDate, unixTime, formatDate, formatUnix } from "@/formatDate.js";

Vue.config.productionTip = false

Vue.prototype.$axios = AXIOS
Vue.prototype.$helper = helper
Vue.prototype.$util = require("util")
Vue.prototype.$moment = moment
Vue.prototype.$formatUnixDate = formatUnixDate
Vue.prototype.$unixTime = unixTime
Vue.prototype.$formatDate = formatDate
Vue.prototype.$formatUnix = formatUnix
Vue.prototype.$urls = require("@/urls.json")

Vue.component("Divider", () => import("@/components/Divider"))
Vue.component("TextField", () => import("@/components/form/TextField"))
Vue.component("TextArea", () => import("@/components/form/TextArea"))
Vue.component("SelectField", () => import("@/components/form/SelectField"))
Vue.component("DatePicker", () => import("@/components/form/DatePicker"))
Vue.component("FileField", () => import("@/components/form/FileField"))
Vue.component("CheckBox", () => import("@/components/form/CheckBox"))
Vue.component("TimePicker", () => import("@/components/form/TimePicker"))
Vue.component("StaffSelect", () => import("@/components/form/StaffSelect"))
Vue.component("DeleteDialog", () => import("@/components/Dialogs/DeleteDialog"))
Vue.component("TableSkeletonLoader", () => import("@/components/TableSkeletonLoader"))
Vue.component("TooltipBtn", () => import("@/components/TooltipIconBtn"))
Vue.component("ListItem", () => import("@/components/ListItem"))

// Marker items were missing.
// Had to implement this workaround
// https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app")
