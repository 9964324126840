const state = {
	itemToDelete: null
}

const getters = {
	item: state => state.itemToDelete
}

const mutations = {
	SET_ITEM_TO_DELETE: (state, value) => state.itemToDelete = value,
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
