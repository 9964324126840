import moment from "moment";

const verboseFormat = "Do MM, YYYY"
const formDateFormat = "YYYY-MM-DD"

export function formatUnixDate(date) {
	if (date) return moment.unix(date).format(formDateFormat)
	else return null
}

export function unixTime(date) {
	if(date) return moment.unix(date).format("HH:mm")
	return "-"
}

export function formatDate(date) {
	if(date) return moment(date).format(verboseFormat)
	return "-"
}

export function formatUnix(date) {
	if (date) return moment.unix(date).format(verboseFormat)
	return "-"
}
