const state = {
	leaves: [],
	leaveInEdit: {},
	errors: {},
	editorDialog: false,
}

const getters = {
	list: state => state.leaves,
	dialog: state => state.editorDialog,
	inEdit: state => state.leaveInEdit,
	formErrors: state => state.errors,
}

const mutations = {
	SET_LIST: (state, value) => state.leaves = value,
	SET_FOR_EDIT: (state, value) => state.leaveInEdit = value,
	SET_FORM_ERRORS: (state, value) => state.errors = value,
	SET_DIALOG_STATE: (state, value) => state.editorDialog = value
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
