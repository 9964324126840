module.exports = {
	STATUS_CHOICES: [
		{code: "AP", name: "Approved", icon: "mdi-check-bold", color: "success"},
		{code: "RE", name: "Rejected", icon: "mdi-close", color: "error"},
		{code: "PE", name: "Pending", icon: "mdi-timer-sand", color: "info"},
		{code: "RC", name: "Recommended", icon: "mdi-account-voice", color: "primary"}
	],
	LEAVE_TYPES: [
		{code: "SL", name: "Sick leave", color: "orange"},
		{code: "HL", name: "Home leave", color: "orange"},
		{code: "CL", name: "Casual leave", color: "info darken-1"},
		{code: "PH", name: "Public holiday", color: "error"},
		{code: "RH", name: "Religious holidays", color: "purple"},
		{code: "ML", name: "Maternity leave", color: "teal"},
		{code: "PL", name: "Paternity leave", color: "success"},
		{code: "BL", name: "Bereavement leave", color: "indigo lighten-1"},
		{code: "CM", name: "Compensatory leave", color: "blue-grey"},
		{code: "SA", name: "Sabbatical leave", color: "amber"},
		{code: "UL", name: "Unpaid Leave", color: "grey"}
	],
	getAccessToken() {
		return localStorage.getItem("attendance-api-access-token")
	},
	getCurrentUser() {
		const data = localStorage.getItem("username")
		if (data) return JSON.parse(data)
		else return null
	},
	isAdmin() {
		const currentUser = this.getCurrentUser()
		if (currentUser) {
			return currentUser["is_admin_staff"]
		}
		return false
	},
	isManager() {
		const currentUser = this.getCurrentUser()
		if (currentUser) {
			return currentUser["is_manager"]
		}
		return false
	},
	isManagerOrAdmin() {
		return (this.isAdmin() || this.isManager())
	},
	clearAccessToken() {
		localStorage.removeItem("attendance-api-access-token")
	},
	setAccessToken(token) {
		this.clearAccessToken()
		localStorage.setItem("attendance-api-access-token", token)
	},
	setCurrentUser(value) {
		localStorage.setItem("username", JSON.stringify(value))
	},
	getLatLng(str) {
		let match, m;
		const regex = /Latitude: (?<latitude>[0-9.]+), Longitude: (?<longitude>[0-9.]+)/gm;

		while ((m = regex.exec(str)) !== null) {
			// This is necessary to avoid infinite loops with zero-width matches
			if (m.index === regex.lastIndex) {
				regex.lastIndex++;
			}
			match = m.groups
		}
		return match
	},
	capitalize(str) {
		return str.replace(/\b\w/g, l => l.toUpperCase())
	},
}
