<template>
	<v-app>
		<the-app-bar/>
		<home-drawer v-if="$route.name !== 'Login'"/>
		<the-snackbar/>

		<v-main class="grey lighten-4">
			<v-container fluid>
				<v-card color=" transparent" flat max-width="1600" class="mx-auto">
					<router-view/>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: "App",
	components: {
		TheSnackbar: () => import("@/components/TheSnackbar"),
		TheAppBar: () => import("@/components/TheAppBar"),
		HomeDrawer: () => import("@/components/HomeDrawer")
	}
};
</script>
<style lang="scss">
@import "src/styles/variables.scss";

* {
	margin: 0;
	box-sizing: border-box;
	font-family: "Dosis", sans-serif;
	font-size: 1rem;
}
.full-width {
	width: 100% !important;
}
:root {
	--primary: $primary;
}
.table-toolbar-title {
	font-size: 28px !important;
	font-weight: 600;
	color: $primary;
	text-transform: uppercase;
}
.v-picker {
	border-radius: 8px !important;
}
.v-menu__content {
	border-radius: 8px !important;
}
.v-messages__message {
	line-height: 16px !important;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	background: #d7d7d7;
}
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
	border: 1px solid #cecece;
}
::-webkit-scrollbar-thumb:hover {
	background: #727272;
}
</style>
