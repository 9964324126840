const state = {
	staffs: [],
	editorDialog: null,
	staffInEdit: {},
	errors: {}
}

const getters = {
	list: state => state.staffs,
	dialog: state => state.editorDialog,
	inEdit: state => state.staffInEdit,
	formErrors: state => state.errors
}

const mutations = {
	SET_LIST(state, value) {
		if (value) {
			value.forEach(item => {
				item.full_name = `${item.first_name} ${item.last_name}`
			})
		}
		state.staffs = value
	},
	SET_DIALOG_STATE(state, value) {
		state.editorDialog = value
	},
	SET_FOR_EDIT(state, value) {
		state.staffInEdit = value
	},
	SET_FORM_ERRORS(state, value) {
		state.errors = value
	}
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
