import Vue from "vue"
import Vuex from "vuex"
import staff from "@/store/modules/staff.js"
import toDelete from "@/store/modules/delete.js"
import snack from "@/store/modules/snack.js"
import leave from "@/store/modules/leave.js"
import attendance from "@/store/modules/attendance.js"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		noAuth: false,
		drawer: false,
		loginDialog: false,
		currentUser: null,
		pastApprovalsDialog: false,
		pastApprovals: null
	},
	getters: {
		drawerState: state => state.drawer,
		loginState: state => state.loginDialog,
		currentUser: state => state.currentUser,
		pastApproval: state => state.pastApprovalsDialog,
		approvalList: state => state.pastApprovals
	},
	mutations: {
		SET_DRAWER_STATE: (state, value) => state.drawer = value,
		SET_LOGIN_STATE: (state, value) => state.loginDialog = value,
		SET_CURRENT_USER: (state, value) => state.currentUser = value,
		SET_APPROVAL_DIALOG: (state, value) => state.pastApprovalsDialog = value,
		SET_APPROVALS: (state, value) => state.pastApprovals = value
	},
	actions: {
		setPastApprovalDialog({commit}, approvals) {
			commit("SET_APPROVAL_DIALOG", true)
			commit("SET_APPROVALS",  approvals)
		},
		closeApprovalDialog({commit}) {
			commit("SET_APPROVAL_DIALOG", false)
			commit("SET_APPROVALS",  null)
		}
	},
	modules: {
		staff,
		toDelete,
		snack,
		leave,
		attendance
	}
})
